import React from "react";
import NpsSummary from "./NpsSummary";
import { withAdminRoleRequired } from "config/auth";
import { PermissionsFor } from "constants/permission";

const NPS = () => {
  return (
    <NpsSummary />
  );
};


export default withAdminRoleRequired(NPS, PermissionsFor.NPS);
