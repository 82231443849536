import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import styled from "@emotion/styled";
import { useSearchParams } from "react-router-dom";
import PostModal from "./components/PostModal";
import DateFilter2, { OnSubmit } from "./components/DateFilter2";
import Sort from "./components/Sort";
import {
  FilterType,
  Maybe,
  MisForCreatorsData,
  MisForCreatorsOutput,
  SortOrder,
  SortType,
  useMisForCreatorsQuery,
} from "generated/graphql";
import NoDataFound from "components/NoDataFound";
import { isEmpty } from "lodash-es";
import { CheckCircle } from "@mui/icons-material";

const dateFormat = (date: Dayjs | string) => dayjs(date).format("YYYY-MM-DD");

const toDay = dayjs();
const sevenDayPrevious = dayjs(toDay).subtract(7, "days");

const CreatorsSummary = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    startDate: dateFormat(sevenDayPrevious),
    endDate: dateFormat(toDay),
    filter: FilterType.AllCreators,
    sort: SortOrder.Upvote,
    sortType: SortType.Asc,
  });

  const startDate = searchParams.get("startDate")
    ? dayjs(searchParams.get("startDate"))
    : sevenDayPrevious;
  const endDate = searchParams.get("endDate")
    ? dayjs(searchParams.get("endDate"))
    : toDay;

  const handleClear = () => {
    searchParams.delete("userId");
    searchParams.delete("fullName");
    setSearchParams(searchParams);
  };

  const handleClearUser = (filterValue: any, currentValue: any) => {
    if (filterValue != currentValue) {
      searchParams.delete("userId");
      searchParams.delete("fullName");
      setSearchParams(searchParams);
    }
  };

  const onFilter: OnSubmit = (values) => {
    searchParams.set("startDate", dateFormat(values.startDate));
    searchParams.set("endDate", dateFormat(values.endDate));
    searchParams.set("filter", values.filter);

    if (values.userId) {
      searchParams.set("userId", values.userId);
    }
    if (values.fullName) {
      searchParams.set("fullName", values.fullName);
    }

    setSearchParams(searchParams);
  };
  const dataNew: Maybe<MisForCreatorsData> | undefined = {
    startDate: searchParams.get("startDate") as any,
    endDate: searchParams.get("endDate") as any,
    sort: searchParams.get("sort") as any,
    sortType: searchParams.get("sortType") as any,
    skip: 0,
    take: 100,
  };

  if (searchParams.get("userId")) {
    dataNew.userId = searchParams.get("userId");
  } else {
    dataNew.filter = [searchParams.get("filter") as FilterType];
  }

  const { data, loading } = useMisForCreatorsQuery({
    variables: {
      data: dataNew,
    },
  });

  const handleSortType = () => {
    if (searchParams.get("sortType") === SortType.Asc) {
      searchParams.set("sortType", SortType.Desc);
    } else {
      searchParams.set("sortType", SortType.Asc);
    }

    setSearchParams(searchParams);
  };

  const currentSortOrder = searchParams.get("sort");

  const handleSort = (sort: SortOrder) => {
    searchParams.set("sort", sort);
    if (
      searchParams.get("sortType") === SortType.Asc &&
      searchParams.get("sort") == currentSortOrder
    ) {
      searchParams.set("sortType", SortType.Desc);
    } else {
      searchParams.set("sortType", SortType.Asc);
    }
    setSearchParams(searchParams);
  };

  const [postId, setPostId] = useState<string | undefined>(undefined);
  const [postlink, setPostLink] = useState<string | undefined>(undefined);

  const handleOpenModal = (
    postId: string | undefined,
    postlink: string | undefined
  ) => {
    setPostLink(process.env.STOCKEDGE_SOCIAL_URL as string + postlink);
    setPostId(postId);
  };
  const handleCloseModal = () => setPostId(undefined);

  function MouseOver(event: any) {
    event.target.style.color = "#2196f3";
    event.target.style.textDecoration = "underline";
  }

  function MouseOut(event: any) {
    event.target.style.color = "inherit";
    event.target.style.textDecoration = "none";
  }

  return (
    <>
      <DateFilter2
        initialValues={{
          startDate,
          endDate,
          filter: searchParams.get("filter") as any,
          userId: searchParams.get("userId") as string,
          fullName: searchParams.get("fullName") as string,
        }}
        user={data as MisForCreatorsOutput}
        onSubmit={onFilter}
        onClear={handleClear}
        handleClearUser={handleClearUser}
      />

      <Box sx={{ ml: "36px" }}>
        <p>
          <strong style={{ fontSize: "15px" }}>Total</strong>
        </p>
        <Stack
          direction="row"
          spacing={2}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Box sx={{ paddingRight: "8px" }}>
            <Typography variant="caption">
              Posts : {data?.misForCreators?.Total?.post}
            </Typography>
          </Box>
          <Box sx={{ paddingRight: "8px" }}>
            <Typography variant="caption">
              Upvotes : {data?.misForCreators?.Total?.upvote}
            </Typography>
          </Box>
          <Box sx={{ paddingRight: "8px" }}>
            <Typography variant="caption">
              Downvotes : {data?.misForCreators?.Total?.downvote}
            </Typography>
          </Box>
          <Box sx={{ paddingRight: "8px" }}>
            <Typography variant="caption">
              Shares : {data?.misForCreators?.Total?.share}
            </Typography>
          </Box>
          <Box sx={{ paddingRight: "8px" }}>
            <Typography variant="caption">
              Comments : {data?.misForCreators?.Total?.comment}
            </Typography>
          </Box>
          <Box sx={{ paddingRight: "8px" }}>
            <Typography variant="caption">
              Replies : {data?.misForCreators?.Total?.reply}
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Container>
        <Link>
          <PostModal
            open={Boolean(postId)}
            postlink={postlink}
            postId={postId}
            handleCloseModal={handleCloseModal}
          />
        </Link>
        <TableContainer>
          <Table sx={{ minWidth: 425 }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} sx={{ cursor: "pointer" }}>
                  <strong>Posts</strong>
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ cursor: "pointer", maxWidth: "12%" }}
                  align="left"
                >
                  <strong>Creators</strong>
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ cursor: "pointer", width: "12%" }}
                  align="right"
                >
                  <strong>Engagement</strong>
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ cursor: "pointer", width: "12%" }}
                  align="right"
                  onClick={() => handleSort(SortOrder.Upvote)}
                >
                  <strong>Upvotes</strong>
                  {searchParams.get("sort") === "upvote" ? (
                    <Sort col={"Upvotes"} handle={handleSortType} />
                  ) : null}
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ cursor: "pointer", width: "12%" }}
                  align="right"
                  onClick={() => handleSort(SortOrder.Downvote)}
                >
                  <strong>Downvotes</strong>
                  {searchParams.get("sort") === "downvote" ? (
                    <Sort col={"Downvotes"} handle={handleSortType} />
                  ) : null}
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ cursor: "pointer", width: "12%" }}
                  align="right"
                  onClick={() => handleSort(SortOrder.Share)}
                >
                  <strong>Shares</strong>
                  {searchParams.get("sort") === "share" ? (
                    <Sort col={"Shares"} handle={handleSortType} />
                  ) : null}
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ cursor: "pointer", width: "12%" }}
                  align="right"
                  onClick={() => handleSort(SortOrder.Comment)}
                >
                  <strong>Comments</strong>
                  {searchParams.get("sort") === "comment" ? (
                    <Sort col={"Comments"} handle={handleSortType} />
                  ) : null}
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ cursor: "pointer", width: "12%" }}
                  align="right"
                  onClick={() => handleSort(SortOrder.Reply)}
                >
                  <strong>Replies</strong>
                  {searchParams.get("sort") === "reply" ? (
                    <Sort col={"Replies"} handle={handleSortType} />
                  ) : null}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.misForCreators?.Data?.map((report) => {
                return (
                  <TableRow key={report?.postId}>
                    <TableCell colSpan={2}>
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenModal(
                            report?.postId as any,
                            report?.postLink as any
                          )
                        }
                        onMouseOver={(e) => MouseOver(e)}
                        onMouseOut={(e) => MouseOut(e)}
                      >
                        {report?.postId}
                      </Link>
                    </TableCell>
                    <TableCell colSpan={2} align="left">
                      <Link
                        href={report?.profileLink as string}
                        target="__blank"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Box sx={{ display: "inline-block" }}>
                            {report?.fullname}
                          </Box>
                          <Box style={{ padding: "2px" }}>
                            {report?.bluetick ? (
                              <CheckCircle
                                color="primary"
                                sx={{ fontSize: "small" }}
                              />
                            ) : report?.greentick ? (
                              <CheckCircle
                                sx={{ color: "green", fontSize: "small" }}
                              />
                            ) : report?.goldentick ? (
                              <CheckCircle
                                sx={{ color: "#fbc02d", fontSize: "small" }}
                              />
                            ) : null}
                          </Box>
                        </Box>
                      </Link>
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      {report?.engagement}
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      {report?.upvote}
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      {report?.downvote}
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      {report?.share}
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      {report?.comment}
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      {report?.reply}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {loading ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: 2 }}
          >
            <CircularProgress />
          </Stack>
        ) : (
          isEmpty(data?.misForCreators?.Data) && <NoDataFound />
        )}
      </Container>
    </>
  );
};
export default CreatorsSummary;

const Container = styled.div`
  padding: 16px;
`;
