import React from "react";
import Users from "./components/users";
import { withAdminRoleRequired } from "config/auth";
import { PermissionsFor } from "constants/permission";

const TickUsers = () => {
  return <Users />;
};

export default withAdminRoleRequired(TickUsers, PermissionsFor.Onboarding);
