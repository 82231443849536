import React, { FC, Fragment, PropsWithChildren } from "react";
import { OIDCAuthProvider, getOIDCUser, useGetRoles } from "./oidc";
import { CustomApolloProvider } from "./apollo";
import { useAdminloginWithOidcv2Query } from "generated/graphql";
import NotAuthenticated from "components/NotAuthenticated";
import AbsoluteLoader from "components/AbsoluteLoader";
import { checkAccessibility } from "constants/permission";
import { Navigate } from "react-router-dom";

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <OIDCAuthProvider>
      <CustomApolloProvider>{children}</CustomApolloProvider>
    </OIDCAuthProvider>
  );
};

export { getOIDCUser, useGetRoles };

export const withAdminAuthRequired = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => {
  const displayName = `withAdminAuthRequired(${
    Component.displayName || Component.name
  })`;
  const C: React.FC<P> = (props) => {
    const user = getOIDCUser();
    const accessToken = user?.access_token;
    const { data, loading } = useAdminloginWithOidcv2Query({
      variables: {
        accessToken: accessToken as string,
      },
      skip: !accessToken,
    });
    return (
      <Fragment>
        {loading ? (
          <AbsoluteLoader loading />
        ) : data?.AdminloginWithOIDCV2?.isUserMemberOfStockEdgeClub ? null : (
          <NotAuthenticated />
        )}
        <Component {...props} />
      </Fragment>
    );
  };

  C.displayName = displayName;

  return C;
};

export const withAdminRoleRequired = <P extends object>(
  Component: React.ComponentType<P>,
  roles: Array<string>
): React.FC<P> => {
  const displayName = `withRoleRequired(${
    Component.displayName || Component.name
  })`;
  const C: React.FC<P> = (props) => {
    const userRoles = useGetRoles();
    return checkAccessibility(userRoles, roles) ? (
      <Component {...props} />
    ) : (
      <Navigate to="/" replace />
    );
  };

  C.displayName = displayName;

  return C;
};
