import { Stack } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import MainBodyThree from "./MainBodyThree";

const dateFormat = (date: Date | string | undefined | null) =>
  date ? dayjs(date).format("YYYY-MM-DD") : "";

const sevenDayPrevious = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
const OneDayPrevious = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000);
const OneMonthPrevious = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
const toDay = new Date();
const OneYearPrevious = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);

const Home = () => {
  const [searchParams] = useSearchParams({
    period: "lastWeek",
  });

 

  const [value] = React.useState<Dayjs | null | any>({
    startDate: dateFormat(sevenDayPrevious),
    endDate:
      searchParams.get("period") == "today"
        ? dateFormat(toDay)
        : dateFormat(OneDayPrevious),
  });

  const values = useMemo(() => {
    let startDate = dateFormat(OneYearPrevious);
    let endDate =
      searchParams.get("period") == "today"
        ? dateFormat(toDay)
        : dateFormat(OneDayPrevious);

    if (searchParams.get("period") == "today") {
      startDate = dateFormat(toDay);
    } else if (searchParams.get("period") == "lastDay") {
      startDate = dateFormat(OneDayPrevious);
    } else if (
      searchParams.get("period") == "lastWeek" ||
      searchParams.get("period") == null
    ) {
      startDate = dateFormat(sevenDayPrevious);
    } else if (searchParams.get("period") == "lastMonth") {
      startDate = dateFormat(OneMonthPrevious);
    } else if (searchParams.get("period") == "advanced") {
      startDate = dateFormat(value?.startDate);
      endDate = dateFormat(value?.endDate);
    }
      console.log("start date advanced filter", startDate);

    return {
      startDate,
      endDate,
    };
  }, [searchParams, value]);

  return (
    <Stack>
      <MainBodyThree timePeriod={values} searchParams={searchParams} />
    </Stack>
  );
};

export default Home;
