import React from "react";
import AllInterstitials from "./AllInterstitials";
import { withAdminRoleRequired } from "config/auth";
import { PermissionsFor } from "constants/permission";


const Interstitials = () => {
  return (
    <>
      <AllInterstitials />
    </>
  );
};

export default withAdminRoleRequired(Interstitials, PermissionsFor.Interstitials);

