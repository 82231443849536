import React, { FC, Fragment, lazy, Suspense } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Button, CircularProgress, Stack } from "@mui/material";
import styled from "@emotion/styled";
import { useDailySignupSummaryQuery } from "generated/graphql";

import { isEmpty } from "lodash-es";
import NoDataFound from "components/NoDataFound";
import { useLocation, useSearchParams } from "react-router-dom";
import DateFilter, { OnSubmit } from "./components/DateFilter";
import CustomizeMISTable from "./components/CustomizeMISTable";

import { LazyLoadComponent } from "react-lazy-load-image-component";
import TableGraphToggle from "components/TableGraphToggle";
import { withAdminRoleRequired } from "config/auth";
import { PermissionsFor } from "constants/permission";
import { exportSignUpData } from "api/rest";

const MISIntersectingChart = lazy(
  () => import("../../components/charts/MISIntersectingChart")
);

const dateFormat = (date: Dayjs | string) => dayjs(date).format("YYYY-MM-DD");

const toDay = dayjs();
const sevenDayPrevious = dayjs(toDay).subtract(7, "days");

const UserEngagementSummary: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    startDate: dateFormat(sevenDayPrevious),
    endDate: dateFormat(toDay),
  });

  const startDate = searchParams.get("startDate")
    ? dayjs(searchParams.get("startDate"))
    : sevenDayPrevious;
  const endDate = searchParams.get("endDate")
    ? dayjs(searchParams.get("endDate"))
    : toDay;

  const { data, loading, refetch } = useDailySignupSummaryQuery({
    variables: {
      data: {
        startDate: dateFormat(startDate),
        endDate: dateFormat(endDate),
      },
    },
  });

  const onFilter: OnSubmit = (values) => {
    searchParams.set("startDate", dateFormat(values.startDate));
    searchParams.set("endDate", dateFormat(values.endDate));
    setSearchParams(searchParams);
  };

  const downloadCsv = () => {
    exportSignUpData({
      startDate: searchParams.get("startDate"),
      endDate: searchParams.get("endDate"),
    })
      .then((response) => {
        // Create a blob from the response data
        const blob = new Blob([response.data], { type: "text/csv" });

        // Create a link element
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);

        // Set the filename for the downloaded file
        downloadLink.setAttribute("download", `mis_signup_${Date.now()}.csv`);
        // Append the link to the body
        document.body.appendChild(downloadLink);

        // Trigger the click event of the link to start download
        downloadLink.click();

        // Clean up: remove the link from the DOM
        document.body.removeChild(downloadLink);
      })
      .catch((error) => {
        console.error("Error downloading CSV:", error);
      });
  };

  // CHECKING URL FOR DAILY SIGNUP SUMMARY AND TYPES OF DATA(GRAPH/TABLE)
  const location = useLocation();
  const checkSignupSummaryGraph =
    location.pathname === "/mis/daily-signup-summary" &&
    searchParams.get("type") === "graph" &&
    !isEmpty(data?.dailySignUpSummary?.dataValues);
  const checkSignupSummaryTable =
    location.pathname === "/mis/daily-signup-summary" &&
    (searchParams.get("type") === "table" || !searchParams.get("type"));

  return (
    <>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <DateFilter
          initialValues={{
            startDate,
            endDate,
          }}
          onSubmit={onFilter}
        />
        <Button onClick={downloadCsv} sx={{ marginRight: 2 }}>
          Download CSV
        </Button>
      </Stack>

      <Container>
        <TableGraphToggle />

        {checkSignupSummaryTable && (
          <CustomizeMISTable
            dataHeaders={data?.dailySignUpSummary?.dataHeaders}
            dataValues={data?.dailySignUpSummary?.dataValues}
          />
        )}
        {checkSignupSummaryGraph && (
          <LazyLoadComponent>
            <Suspense fallback={<Fragment />}>
              <MISIntersectingChart
                data={data?.dailySignUpSummary}
                loading={loading}
                refetch={refetch}
              />
            </Suspense>
          </LazyLoadComponent>
        )}

        {loading ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: 2 }}
          >
            <CircularProgress />
          </Stack>
        ) : (
          isEmpty(data?.dailySignUpSummary?.dataValues) && <NoDataFound />
        )}
      </Container>
    </>
  );
};

export default withAdminRoleRequired(UserEngagementSummary, PermissionsFor.MIS);

const Container = styled.div`
  padding: 16px;
`;
