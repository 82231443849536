import React, { Fragment } from "react";
import AddEvent from "./AddEvent";
import Events from "./Events";
import { withAdminRoleRequired } from "config/auth";
import { PermissionsFor } from "constants/permission";

const Event = () => {
    return (
        <Fragment>
            <AddEvent />
            <Events />
        </Fragment>
    )
}

export default withAdminRoleRequired(Event, PermissionsFor.Events);
