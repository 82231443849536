import React, { Fragment } from "react";
import FeatureListTable from "./FeatureListTable";
import AddFeature from "./AddFeature";
import { withAdminRoleRequired } from "config/auth";
import { PermissionsFor } from "constants/permission";


const Feature = () => {
  return (
    <Fragment>
      <AddFeature />
      <FeatureListTable />
    </Fragment>
  );
};


export default withAdminRoleRequired(Feature, PermissionsFor.Feature);
