import React from "react";
import ClubCategoryList from "./ClubCategoryList";
import AddClubCategory from "./AddClubCategory";
import { Stack } from "@mui/material";
import { withAdminRoleRequired } from "config/auth";
import { PermissionsFor } from "constants/permission";

const ClubCategories = () => {
  return (
    <>
      <Stack>
        <AddClubCategory />
      </Stack>
      <ClubCategoryList />
    </>
  );
};

export default withAdminRoleRequired(ClubCategories, PermissionsFor.Clubs);
