import React, { FC, Fragment } from "react";
import { useParams } from "react-router";
import styled from "@emotion/styled";
import { ReportedBy } from "./ReportedBy";
import {
    useGetSiteReportByIdQuery,
    ReportedOn
} from "generated/graphql";
import { Progress, ProgressItem } from "./Progress";
import { ContentDisplay } from "./ContentDisplay";
import { ReportHeader } from "./ReportHeader";
import { ReportAction } from "./ReportAction";
import { Avatar } from "@mui/material";
import { UserReportAction } from "./UserReportAction";
import { withAdminRoleRequired } from "config/auth";
import { PermissionsFor } from "constants/permission";

const ViewReport: FC = () => {
    const { reportId: siteReportId } = useParams<{ reportId: string }>();
    const { data } = useGetSiteReportByIdQuery({
        variables: {
            siteReportId: `${siteReportId}`,
        },
    });

    return (
        <Fragment>
            <ReportHeader
                reportCount={data?.getSiteReportById?.reportCount || 0}
                lastReportedTime={data?.getSiteReportById?.lastReportedTime}
            />
            <Grid>
                <GridItem>
                    {
                        data?.getSiteReportById?.reportedOn === ReportedOn.User
                            ?
                            <Fragment>
                                <Title>Content</Title>
                                <ContainerBorder>
                                    <Header>
                                        <Avatar src={data?.getSiteReportById?.User?.avatar?.LowResUri} />
                                        <NameContainer>
                                            <Name>{`${data?.getSiteReportById?.User?.firstName} ${data?.getSiteReportById?.User?.lastName}`}</Name>
                                            <Flex>
                                                <Username>@{data?.getSiteReportById?.User?.username}</Username>
                                            </Flex>
                                        </NameContainer>
                                    </Header>
                                </ContainerBorder>
                            </Fragment>
                            :
                            <Fragment>
                                <Title>Categories</Title>
                                <ProgressContainer>
                                    <Progress
                                        width={320}
                                        data={{
                                            spamcount: data?.getSiteReportById?.spamcount,
                                            othercount: data?.getSiteReportById?.othercount,
                                            abusecount: data?.getSiteReportById?.abusecount,
                                            hatefulcount: data?.getSiteReportById?.hatefulcount,
                                        }}
                                    />
                                    <div style={{ marginTop: 10 }}>
                                        <ProgressItem />
                                    </div>
                                </ProgressContainer>
                                <Title>Content</Title>
                                <ContentDisplay post={data?.getSiteReportById?.Post} />
                            </Fragment>
                    }
                    <Title>Reported By</Title>
                    <ReportedBy siteReportId={siteReportId} />
                </GridItem>
                <GridItem>
                    {
                        data?.getSiteReportById?.reportedOn === ReportedOn.User
                            ?
                            <UserReportAction />
                            :
                            <ReportAction />
                    }
                </GridItem>
            </Grid>
        </Fragment>
    );
};

export default withAdminRoleRequired(ViewReport, PermissionsFor.Reports);

const ContainerBorder = styled.div`
  background: #FFFFFF;
  border: 1px solid #7B7B7B;
  border-radius: 8px;
  margin: 10px 0;
  padding: 10px;
  overflow: hidden;
  textarea {
    border: 0;
    flex: 1;
    outline: 0;
    width: 100%;
    padding: 10px;
    resize: none;
    height: 200px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.65);
  }
`;


const Grid = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
`;
const GridItem = styled.div``;

const Title = styled.div<{ right?: boolean }>`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #252733;
  ${({ right }) =>
        right &&
        `
        text-align: right;
    `}
`;

const ProgressContainer = styled.div`
  padding: 20px 0;
  ul {
    margin: 0 !important;
    padding-bottom: 10px;
  }
`;



const Header = styled.div`
  display: flex;
`;
const NameContainer = styled.div`
  margin-left: 10px;
`;
const Name = styled.div`
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
`;
const Username = styled.span`
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #9597A1;
`;

const Flex = styled.div`
  display: flex;
`;