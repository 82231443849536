import React, { Fragment } from "react";
import CreatePushNotifications from "./CreatePushNotifications";
import PushedNotifications from "./PushedNotifications";
import { useSearchParams } from "react-router-dom";
import PushedNotificationsFilter, { OnSubmit } from "./components/PushedNotificationsFilter";
import { PushNotificationFilter } from "generated/graphql";
import { Box } from "@mui/material";
import { withAdminRoleRequired } from "config/auth";
import { PermissionsFor } from "constants/permission";

const PushNotifications = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const onFilter: OnSubmit = (values) => {
    setSearchParams({ ...values, page: "1" });
  };
  return (
    <Fragment>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <PushedNotificationsFilter
          initialValues={{
            filter: (searchParams.get("filter") ||
              PushNotificationFilter?.Completed) as PushNotificationFilter,
          }}
          onSubmit={onFilter}
        />
        <CreatePushNotifications />
      </Box>
      <PushedNotifications />
    </Fragment>
  );
};

export default withAdminRoleRequired(PushNotifications, PermissionsFor.PushNotifications);
